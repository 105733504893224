<template>
  <!-- 机票列表 单程 -->
  <SearchUI :query="query" @updateDate="updateDate" @chooseTicket="chooseTicket" />
</template>

<script>
import { mapGetters } from "vuex";
import SearchUI from "./components/SearchAircraft";
export default {
  components: { SearchUI },
  computed: {
    ...mapGetters(["aircraftOrderInfo"]),
    // 丢给子组件的查询参数
    query: function () {
      let obj = {
        depCityCode: this.aircraftOrderInfo.fromCode,
        arrCityCode: this.aircraftOrderInfo.toCode,
        depDate: this.aircraftOrderInfo.date[0],
        corp: null
      }
      return obj
    }
  },
  methods: {
    // 更新aircraftOrderInfo的时间，把整个aircraftOrderInfo结构都传过去
    updateDate(time) {
      this.$set(this.aircraftOrderInfo.date, 0, time);
      this.$store.dispatch(
        "aircraft/setAircraftOrderInfo",
        this.aircraftOrderInfo
      )
    },
    // 选票（比价在子组件完成了）
    chooseTicket(tickets, query) {
      // 把比价结果放进vuex
      this.$store.dispatch("aircraft/setAircraftTicket", {
        data: tickets,
        index: 0
      });
      this.$router.push({
        path: "/orderAircraft",
        query: query
      });
    }
  }
}
</script>

